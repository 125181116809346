@tailwind base;
@tailwind components;
@tailwind utilities;



@media (max-width: 767px) {
	:root {
		--h2-font-size: 16vw;
	}
}

html {
  scroll-behavior: smooth;
}


.bg-before:before {
	content: "";
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 1;
	z-index: 1;
	mix-blend-mode: initial;
	background-color: transparent;
	transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.logo {
  margin-top: 70px !important;
  z-index: 9999;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

/* Remove margin-top for smaller screens */
@media screen and (max-width: 768px) {
  .logo {
    margin-top: 0 !important; /* Remove top margin */
    max-width: 275px;
  }
}
.video {
  position: absolute;
  z-index: 1; /* Set lower than the logo */
}


/* ***** Banner ***** */




.banner .container {
  padding: 20px;
	max-width: 700px;
}

.banner h1 {
	font-size: 5rem;
}

.banner .video {
	mix-blend-mode: luminosity;
}

.banner .video:before {
	background-image: url("/public/overlay.png");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}



@media (max-width: 767px) {
	.banner h1 {
		font-size: 9vw;
	}
}
@media (max-width: 767px) {
	:root {
		--h2-font-size: 16vw;
	}
}
.fh-element {
  font-weight: bolder;
  color: rgba(56,204,254,255); /* Replace 'red' with your desired color */
}
.fh-element1{
  font-weight: bolder;
  color: rgba(255,255,255,255);
}

@media (max-width: 767px) {
  .bob {
    opacity: 0;
  }
}
/* Hide video and show background on smaller screens */
/* Hide video on small screens */
@media (orientation: portrait) and (max-width: 767px) {
  .video {
    display: block !important;
  }

  .bg-before {
    display: none !important; /* Show fallback image */
  }
}

/* Show video on larger screens */
@media (orientation: landscape) and (min-width: 768px) {
  .video {
    display: none !important;
  }

  .bg-before {
    display: block !important; /* Hide fallback image */
  }
}
