


.close-container {
  
  position: relative;
  width: 150px;
  height: 150px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  -webkit-transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  -moz-transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  -ms-transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  -o-transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
}
@media (max-width: 767px) {
  .close-container {
    top: -70px; /* Move up on phones */
    
  }
}
.close-container:hover {
  -webkit-transform: -webkit-rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.close-container:after {
  content: "";
  display: block;
  position: absolute;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 6px solid #fff;
  -webkit-transform: scale(0, 0.2);
  -moz-transform: scale(0, 0.2);
  -ms-transform: scale(0, 0.2);
  -o-transform: scale(0, 0.2);
  transform: scale(0, 0.2);
  -webkit-transition: all 0.25s cubic-bezier(0.3, 0, 0.2, 1);
  -moz-transition: all 0.25s cubic-bezier(0.3, 0, 0.2, 1);
  -ms-transition: all 0.25s cubic-bezier(0.3, 0, 0.2, 1);
  -o-transition: all 0.25s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.25s cubic-bezier(0.3, 0, 0.2, 1);
}
.close-container .cross-line {
  position: absolute;
  width: 100%;
  height: 6px;
  border-radius: 6px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.close-container .cross-line.top-left {
  -webkit-transform: -webkit-rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.close-container .cross-line.top-right {
  -webkit-transform: -webkit-rotate(135deg);
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.close-container .cross-line.bottom-left {
  -webkit-transform: -webkit-rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.close-container .cross-line.bottom-right {
  -webkit-transform: -webkit-rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.close-container .cross-line:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  border-radius: 6px;
  background-color: #fff;
  -webkit-transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  -moz-transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  -ms-transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  -o-transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
}
.close-container:hover:after {
  -webkit-transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  -moz-transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  -ms-transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  -o-transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.4s cubic-bezier(0.3, 0, 0.2, 1);
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}
.close-container:hover .cross-line:before {
  width: 18%;
  -webkit-transform: translate(320%, 0);
  -moz-transform: translate(320%, 0);
  -ms-transform: translate(320%, 0);
  -o-transform: translate(320%, 0);
  transform: translate(320%, 0);
}




