@property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
  }
  

  /* Base card styles */
/* Ensure :root variables for card dimensions */

  
  /* Basic Card Styles */
  .card {
    background: #191c29;
    padding: 10px;
    position: relative;
    border-radius: 12px !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    font-size: 1.5em;
   
    
    transition: color 1s;
  }
  
  .card:hover {
    color: rgba(88, 199, 250, 1);
    
  }
  
  .card::before {
    content: "";
    width: 104%;
    height: 104%;
    border-radius: 12px;
    background-image: linear-gradient(
      var(--rotate, 0deg),
      #5ddcff,
      #3c67e3 43%,
      #4e00c2
    );
    position: absolute;
    z-index: -1;
    top: -2%;
    left: -2%;
   
  }
  
  .card::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    filter: blur(30px);
    border-radius: 8px;
    
    background: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2);
    animation: rotate-border 3s linear infinite;
  }
  
  @keyframes rotate-border {
    0% {
      --rotate: 0deg;
    }
    100% {
      --rotate: 360deg;
    }
  }
  
  @media (max-width: 768px) {
    .card {
      width: 100%;
      height: 100%;
    }
  }
  
  @media (min-width: 768px) and (max-width: 99999px) {
    .card {
      width: 100%;
      height: 100%;
    }
  }
  .card .price {
   
    color: white;
    transition: color 0.3s;
  }

.card:hover .price {
    color: #ffde59;
  }